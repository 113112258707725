import Page from '../../components/Page';
import { Tag } from 'antd';
import moment from "moment"
import Table from "../../components/Table"
import { useState } from "react";

const formatter = new Intl.NumberFormat('de-DE', {
    style: 'currency',
    currency: 'EUR'
});

export default () => {
    const [ refresh, setRefresh ] = useState()

    const columns = [
        {
            title: "Name",
            key: "name",
            dataIndex: "name"
        },
        {
            title: "Preis",
            key: "price",
            dataIndex: "price",
            valueType: "digit",
            render: (_, { price }) => {
                return <div>{formatter.format(price)}</div>
            }
        },
        {
            title: "Zifferblatt Serviceart",
            key: "dialService",
            dataIndex: "dialService",
            valueType: "select",
            valueEnum: convertMap(dialMap)
        },
        {
            title: "Rest. Serviceart",
            key: "restaurationService",
            dataIndex: "restaurationService",
            valueType: "select",
            valueEnum: convertMap(restaurationMap)
        },
        {
            title: "Allg. Serviceart",
            key: "generalService",
            dataIndex: "generalService",
            valueType: "select",
            valueEnum: convertMap(serviceMap)
        },
        {
            title: "Zusatz Serviceart",
            key: "optionalService",
            dataIndex: "optionalService",
            valueType: "select",
            valueEnum: convertMap(optionalMap)
        },
        {
            title: "Erstellt",
            key: "createdAt",
            dataIndex: "createdAt",
            readonly: true,
            render: val => moment(val).fromNow()
        },
    ];

    return <Page title="Artikel">
        <Table
            columns={columns}
            modes={[ "create", "edit", "delete" ]}
            title="Artikel im System (neuste zuerst)"
            refresh={setRefresh}
            onDelete={async article => {
                const result = await fetch(`${global.WatchStorage.backendUrl}/api/v1/articles`, {
                    method: "DELETE",
                    headers: {
                        "Content-Type": "application/json",
                        "Authorization": `Bearer ${global.WatchStorage.session.token}`
                    },
                    body: JSON.stringify({
                        id: article.id
                    })
                })

                return result.status === 200
            }}
            onCreate={async article => {
                const result = await fetch(`${global.WatchStorage.backendUrl}/api/v1/articles`, {
                    method: "POST",
                    headers: {
                        "Content-Type": "application/json",
                        "Authorization": `Bearer ${global.WatchStorage.session.token}`
                    },
                    body: JSON.stringify({
                        name: article.name,
                        price: article.price,
                        optionalService: article.optionalService,
                        dialService: article.dialService,
                        restaurationService: article.restaurationService,
                        generalService: article.generalService
                    })
                })

                refresh()

                return result.status === 201
            }}
            onEdit={async article => {
                const result = await fetch(`${global.WatchStorage.backendUrl}/api/v1/articles`, {
                    method: "PATCH",
                    headers: {
                        "Content-Type": "application/json",
                        "Authorization": `Bearer ${global.WatchStorage.session.token}`
                    },
                    body: JSON.stringify({
                        id: article.id,
                        name: article.name,
                        price: parseFloat(article.price),
                        optionalService: article.optionalService || null,
                        dialService: article.dialService || null,
                        restaurationService: article.restaurationService || null,
                        generalService: article.generalService || null
                    })
                })

                refresh()

                return result.status === 200
            }}
            request={async () => {
                const data = await fetch(`${global.WatchStorage.backendUrl}/api/v1/articles`, {
                    method: "GET",
                    headers: {
                        "Content-Type": "application/json",
                        "Authorization": `Bearer ${global.WatchStorage.session.token}`
                    }
                })

                if(data.status !== 200) return ({
                    data: [],
                    total: 0,
                    success: false
                })

                const users = await data.json()

                return ({
                    data: users,
                    total: users.length,
                    success: true
                })
            }}
        />
    </Page>
}

export const serviceMap = {
    watchNotRunning: "Uhr läuft nicht mehr",
    gearDeviation: "Starke Gangabweichungen",
    workFunction: "Werkfunktionen beeinträchtigt",
    waterProofness: "Wasserdichtigkeit",
    waterDamage: "Wasserschaden",
    housingDamage: "Schäden an Gehäuse und/oder Band",
    aesthetics: "Ästhetik (Kratzer, Abnutzung, Sturz etc.)",
    dirt: "Verschmutzung"
}

export const restaurationMap = {
    ultrasound: "Nur Reinigung von Band und Gehäuse im Ultraschall",
    housingRefreshment: "Leichte Auffrischung von Band und Gehäuse",
    housingPolish: "Bitte um Politur von Band und Gehäuse (leichte bis mittlere Kratzer werden entfernt)",
    housingRestauration: "Restauration von Band und Gehäuse (neue Stifte / Lasern etc)"
}

export const dialMap = {
    brightening: "Wiederaufhellung (Superluminova, nicht leuchtende oder moderne kurz leuchtende Pigmente)",
    illuminantRestauration: "Bitte um Verwendung von altem Leuchtmittel zur Wiederherstellung",
    dangerousIlluminantRestauration: "Restaurierung alter gefährlicher Leuchtstoffe (Altbestand)",
    illuminantRemoval: "Entfernung von altem Leuchtstoff",
    dialRestauration: "Zifferblattrestaurierung (Entfernen von Flecken, Kratzern, Korrekturen am Zifferblatt",
    clockHandRepainting: "Volle Uhrzeigerneulackierung",
    handAging: "Alterung der Zeiger/des Zifferblatts (nur in Verbindung mit Neulackierung oder Wiederaufhellung)",
    modernHandBrightening: "Zeigerwiederaufhellung mit modernen Pigmenten (kostenlos in Kombination mit Zifferblattwiederaufhellung)",
    tritiumHandBrightening: "Zeigerwiederaufhellung mit Tritium Pigmenten (kostenlos in Kombination mit Zifferblattwiederaufhellung)"
}

export const optionalMap = {
    photography: "Detaillierte Fotografie von Uhrenkomponenten plus 3 druckfertige Porträts mit Studiolicht (Bilder in volle Auflösung)"
}

const convertMap = map => {
    let object = {}

    Object.keys(map).forEach(key => {
        object[key] = { text: map[key] }
    })

    return object
}
