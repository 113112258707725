import React from 'react';
import {
    DashboardOutlined,
    UserSwitchOutlined,
    LoginOutlined,
    UsergroupAddOutlined,
    ClockCircleOutlined,
    ProductOutlined
} from '@ant-design/icons';
import { Layout, Menu, theme } from 'antd';
import Cookies from 'js-cookie';
import { useLocation, useNavigate } from 'react-router-dom';

const { Header, Content, Footer, Sider } = Layout;

export default props => {
    const {
        token: { colorBgContainer, borderRadiusLG },
    } = theme.useToken();

    const navigate = useNavigate()
    const location = useLocation()

    return (
        <Layout style={{ height: "100%" }}>
            <Sider
                breakpoint="lg"
                collapsedWidth="0"
                width={256}
            >
                <div style={{ height: "100%" }}>
                    <div style={{
                        height: '100%',
                        display: 'flex',
                        flexDirection: 'column',
                        boxSizing: "border-box",
                        paddingBottom: 8,
                        justifyContent: 'space-between',
                    }}>
                        <div>
                            <div className="logo" style={{ marginBottom: 16 }}>
                                <img src="/logo_white.svg" style={{ width: '80%', marginLeft: '10%', marginTop: 32 }} />
                            </div>
                            <p style={{ marginBottom: 8, width: "100%", boxSizing: "border-box", paddingLeft: 32, marginTop: 24, paddingBottom: 4, color: "rgba(255, 255, 255, 0.5)", borderBottom: "1px solid rgba(255, 255, 255, 0.1)" }}>Funktionen</p>
                            <Menu theme="dark" mode="inline" defaultSelectedKeys={[location.pathname.substring(1)]}
                                  items={[
                                      /*{
                                          key: '',
                                          icon: <DashboardOutlined />,
                                          label: 'Dashboard',
                                      },
                                      {
                                          key: 'watches',
                                          icon: <ClockCircleOutlined />,
                                          label: 'Uhren im Inventar',
                                      },*/
                                      {
                                          key: 'services',
                                          icon: <UserSwitchOutlined />,
                                          label: 'Serviceanfragen',
                                      }
                                  ]} onClick={data => {
                                navigate(`/${data.key}`);
                            }} />
                            <p style={{
                                marginBottom: 8,
                                width: '100%',
                                boxSizing: 'border-box',
                                paddingLeft: 32,
                                marginTop: 24,
                                paddingBottom: 4,
                                color: 'rgba(255, 255, 255, 0.5)',
                                borderBottom: '1px solid rgba(255, 255, 255, 0.1)',
                            }}>Administration</p>
                            <Menu theme="dark" mode="inline" defaultSelectedKeys={[location.pathname.substring(1)]} items={[
                                {
                                    key: "articles",
                                    icon: <ProductOutlined />,
                                    label: "Artikel verwalten"
                                },
                                {
                                    key: "users",
                                    icon: <UsergroupAddOutlined />,
                                    label: "Benutzer verwalten"
                                }
                            ]} onClick={data => {
                                navigate(`/${data.key}`)
                            }} />
                        </div>
                        <div>
                            <div className="user" style={{ userSelect: "none", boxSizing: "border-box", color: "white", padding: "0 32px", borderBottom: "1px solid rgba(255, 255, 255, 0.1)", paddingBottom: 24, marginBottom: 16 }}>
                                <div style={{ fontSize: 18 }}>
                                    {global.WatchStorage.session.username}
                                </div>
                                <div style={{ opacity: 0.5 }}>
                                    {global.WatchStorage.session.email}
                                </div>
                            </div>
                            <Menu theme="dark" mode="inline" onClick={() => {
                                Cookies.remove('session');

                                window.open('/login', "_self")
                            }} items={[
                                {
                                    key: "logout",
                                    icon: <LoginOutlined />,
                                    label: "Abmelden"
                                }
                            ]} />
                        </div>
                    </div>
                </div>
            </Sider>
            <Layout>
                <Header style={{
                    fontSize: 20,
                    fontWeight: 400,
                    userSelect: "none",
                    padding: 0,
                    paddingLeft: 24,
                    paddingRight: 24,
                    background: colorBgContainer,
                }}>
                    <div style={{ width: "100%", display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                        {props.title}
                        {props.rightContent}
                    </div>
                </Header>
                <Content style={{ margin: '24px' }}>
                    <div
                        style={props.noBackground ? {} : {
                            padding: 24,
                            background: colorBgContainer,
                            borderRadius: borderRadiusLG,
                        }}
                    >
                        {props.children}
                    </div>
                </Content>
                <Footer style={{ textAlign: 'center' }}>
                    WatchManager ©{new Date().getFullYear()} Horoluma GmbH
                </Footer>
            </Layout>
        </Layout>
    );
};
