import Page from '../../components/Page';
import { Tag } from 'antd';
import moment from "moment"
import Table from "../../components/Table"
import { useState } from "react";

export default () => {
    const [ refresh, setRefresh ] = useState()

    const columns = [
        {
            title: "Registriert",
            key: "createdAt",
            dataIndex: "createdAt",
            readonly: true,
            render: val => moment(val).fromNow()
        },
        {
            title: "Benutzername",
            key: "name",
            dataIndex: "name",
            readonly: true
        },
        {
            title: "E-Mail",
            key: "email",
            dataIndex: "email",
            readonly: true
        },
        {
            title: "Rolle",
            key: "role",
            dataIndex: "role",
            valueType: "select",
            readonly: true,
            valueEnum: {
                user: { text: 'Benutzer', status: 'Default' },
                administrator: { text: 'Administrator', status: 'Warning' }
            },
            render: role => <Tag>{role}</Tag>
        },
        {
            title: "Letzte Anmeldung",
            readonly: true,
            key: "lastLoginAt",
            dataIndex: "lastLoginAt",
            render: val => {
                if(!moment(val).isValid()) return "Noch nie"

                return moment(val).fromNow()
            }
        }
    ];

    return <Page title="Benutzer">
        <Table
            columns={columns}
            modes={[]}
            title="Benutzer im System (neuste zuerst)"
            refresh={setRefresh}
            request={async () => {
                const data = await fetch(`${global.WatchStorage.backendUrl}/api/v1/user/all`, {
                    method: "GET",
                    headers: {
                        "Content-Type": "application/json",
                        "Authorization": `Bearer ${global.WatchStorage.session.token}`
                    }
                })

                if(data.status !== 200) return ({
                    data: [],
                    total: 0,
                    success: false
                })

                const users = await data.json()

                return ({
                    data: users,
                    total: users.length,
                    success: true
                })
            }}
        />
    </Page>
}
